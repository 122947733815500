@tailwind base;
@tailwind components;
@tailwind utilities;

.project-slider .slick-dots.slick-thumb {
    display: flex !important;
    justify-content: center;
    margin-top: 10px;
    bottom: -40px;
}

.project-slider .slick-dots.slick-thumb li {
    width: 60px;
    height: 40px;
    margin: 0 5px;
}

.project-slider .slick-dots.slick-thumb li a {
    display: block;
    width: 100%;
    height: 100%;
}

.project-slider .slick-dots.slick-thumb li.slick-active {
    border: 2px solid #3B82F6;
    border-radius: 4px;
}

.project-slider .slick-dots.slick-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}